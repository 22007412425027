import React, { createContext, ReactNode, useContext, useState } from 'react';

interface ScaleFactorContextData {
  scale: number;
  setScale: (scale: number) => void;
}

const ScaleContext = createContext<ScaleFactorContextData | undefined>(undefined);

export const ScaleFactorContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [scale, setScale] = useState<number>(1);


  const contextValue: ScaleFactorContextData = {
    scale,
    setScale,
  };

  return <ScaleContext.Provider value={contextValue}>{children}</ScaleContext.Provider>;
};

export const useScaleFactor = () => {
  const context = useContext(ScaleContext);
  if (!context) {
    throw new Error('useScaleFactor must be used within a ScaleFactorContextProvider');
  }
  return context;
};

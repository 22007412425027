exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-cooperative-index-tsx": () => import("./../../../src/pages/cooperative/index.tsx" /* webpackChunkName: "component---src-pages-cooperative-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-local-government-index-tsx": () => import("./../../../src/pages/local-government/index.tsx" /* webpackChunkName: "component---src-pages-local-government-index-tsx" */),
  "component---src-pages-offer-index-tsx": () => import("./../../../src/pages/offer/index.tsx" /* webpackChunkName: "component---src-pages-offer-index-tsx" */),
  "component---src-pages-residents-index-tsx": () => import("./../../../src/pages/residents/index.tsx" /* webpackChunkName: "component---src-pages-residents-index-tsx" */),
  "component---src-pages-social-activists-index-tsx": () => import("./../../../src/pages/social-activists/index.tsx" /* webpackChunkName: "component---src-pages-social-activists-index-tsx" */),
  "component---src-pages-trusted-index-tsx": () => import("./../../../src/pages/trusted/index.tsx" /* webpackChunkName: "component---src-pages-trusted-index-tsx" */),
  "component---src-pages-unions-index-tsx": () => import("./../../../src/pages/unions/index.tsx" /* webpackChunkName: "component---src-pages-unions-index-tsx" */),
  "component---src-pages-vision-index-tsx": () => import("./../../../src/pages/vision/index.tsx" /* webpackChunkName: "component---src-pages-vision-index-tsx" */)
}


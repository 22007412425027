import React from 'react';
import { ScaleFactorContextProvider } from './src/shared/context/scale-factor.context';
import { WindowSizeProvider } from './src/shared/context/window-size.context';
import DeviceProvider from './src/shared/context/device.context';

interface WrapRootElementArgs {
  element: React.ReactNode;
}

export const wrapRootElement = ({ element }: WrapRootElementArgs) => (
  <WindowSizeProvider>
    <DeviceProvider>
      <ScaleFactorContextProvider>
        {element}
      </ScaleFactorContextProvider>
    </DeviceProvider>
  </WindowSizeProvider>
);
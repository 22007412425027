import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';

interface WindowSizeContextProps {
  windowWidth: number;
  windowHeight: number;
  xxs: boolean;
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
}

const WindowSizeContext = createContext<WindowSizeContextProps>({
  windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
  windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
  xxs: false,
  lg: false,
  md: false,
  sm: false,
  xl: false,
  xs: false,
});

const breakpoints = {
  xs: 576,
  sm: 768,
  md: 1024,
  lg: 1200,
  xl: 1440,
};

export const useWindowSize = () => {
  const context = useContext(WindowSizeContext);
  if (!context) {
    throw new Error('useWindowSize must be used within a WindowSizeProvider');
  }
  return context;
};

export const WindowSizeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [windowSize, setWindowSize] = useState({
    windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
    windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
        windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
      });
    });
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }


    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }

    };
  }, []);

  const xxs = useMemo(() => windowSize.windowWidth < breakpoints.xs, [windowSize]);
  const xs = useMemo(() => windowSize.windowWidth >= breakpoints.xs && windowSize.windowWidth < breakpoints.sm, [windowSize]);
  const sm = useMemo(() => windowSize.windowWidth >= breakpoints.sm && windowSize.windowWidth < breakpoints.md, [windowSize]);
  const md = useMemo(() => windowSize.windowWidth >= breakpoints.md && windowSize.windowWidth < breakpoints.lg, [windowSize]);
  const lg = useMemo(() => windowSize.windowWidth >= breakpoints.lg && windowSize.windowWidth < breakpoints.xl, [windowSize]);
  const xl = useMemo(() => windowSize.windowWidth >= breakpoints.xl, [windowSize]);

  return (
    <WindowSizeContext.Provider
      value={{ ...windowSize, xxs, xs, sm, md, lg, xl }}
    >
      {children}
    </WindowSizeContext.Provider>
  );
};
